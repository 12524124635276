import React, { useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Formik, Form } from "formik"
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import { COLORS } from "../../utils"
import FormControl from "@mui/material/FormControl"
import { DatePicker } from "@mui/x-date-pickers"
import { TextField, Typography } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import moment from "moment"
import { loadReturnLotsData } from "../../actions/LotInventory/LotInventory"
import { updateLotQuantity } from "../../actions/LotInventory/LotInventory"
import { generateOrderUnitLabel } from "../../utils/ordersUtils"

const useStyles = makeStyles((theme) => ({
  element: {
    display: "flex",
    width: "672px",
    padding: "0 64px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "fit-content",
  },
  container: {
    color: COLORS.BLACK,
    backgroundColor: COLORS.WHITE,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: theme.spacing(4),
    padding: theme.spacing(4, 6, 3),
    alignSelf: "stretch",
    borderRadius: 8,
    border: `1px solid ${COLORS.GREY_200 || "#E6E6E6"}`, 
  },
  title: {
    width: "100%",
    marginBottom: theme.spacing(1), 
    color: COLORS.BLACK,
    textAlign: "left",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "140%",
  },
  subtitle: {
    width: "100%",
    color: COLORS.BLACK_500 || COLORS.GREY_500, 
    marginBottom: theme.spacing(3), 
    textAlign: "left",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  formControl: {
    position: "relative",
    marginBottom: theme.spacing(2), 
    justifyContent: "flex-end",
    height: "50px",
    width: "100%",
  },
  submitButton: {
    marginTop: theme.spacing(2),
    display: "flex",
    width: "fit-content",
    marginLeft: "auto",
    height: "31px",
    padding: theme.spacing(0, 1),
    borderRadius: 0,
  },
  quantityUnit: {
    position: "absolute",
    bottom: "10%",
    right: theme.spacing(0.5),
    color: theme.palette.grey[500],
    pointerEvents: "none",
    fontSize: "0.95rem",
  },
}))

const LotReturnModal = ({ mode, loading, setIsLoading }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const sortedLots = useSelector((state) => state.lotInventory.lotsEvents)
  const supplierItems = sortedLots.map((item) => item.lot?.orderSupplierItem)

  const [date, setDate] = useState(moment.utc().startOf("day").valueOf())
  const [unitWeight, setUnitWeight] = useState(null)

  const _onDateChange = (resetForm, value) => {
    const isValidDate = moment(value, "YYYY-MM-DD", true).isValid()
    if (isValidDate) {
      const dateWithCurrentTime = value.set({
        h: moment().get("hour"),
        m: moment().get("minute"),
        s: moment().get("second"),
      })
      const dateAtMidnight = moment
        .utc(dateWithCurrentTime)
        .startOf("day")
        .valueOf()
      setDate(dateAtMidnight)
      dispatch(loadReturnLotsData(dateAtMidnight))
    }

    resetForm()
  }

  const formatSupplierItemsOptions = (supplierItems) => {
    const labelMap = supplierItems.reduce(
      (map, { name: label, objectId: value }) => {
        map[label] = map[label] || { label, values: [] }
        map[label].values.push(value)
        return map
      },
      {}
    )

    return Object.values(labelMap).sort((a, b) =>
      a.label.localeCompare(b.label)
    )
  }

  const formatLotNumberOptions = (sortedLots, selectedSupplierItem) => {
    const seen = new Set()
    return sortedLots
      .filter(({ lot }) =>
        selectedSupplierItem?.values.includes(lot?.orderSupplierItem?.objectId)
      )
      .map(({ lot }) => ({
        label: `${lot?.lotNumber} (${moment
          .utc(lot?.dlc)
          .format("DD/MM/YYYY")})`,
        value: lot?.objectId,
        data: lot,
      }))
      .filter(({ value }) => {
        if (seen.has(value)) {
          return false
        }
        seen.add(value)
        return true
      })
  }

  const formatQuantity = (e, setFieldValue) => {
    let { value } = e.target

    value = value && value < 1 ? "" : value
    setFieldValue("quantity", value)
  }

  const validate = useCallback(async (quantity, selectedLot) => {
    const quantityValue =
      !quantity || quantity === ""
        ? selectedLot.data.quantity
        : quantity
    await dispatch(updateLotQuantity(selectedLot.data, quantityValue, mode, date))
    await dispatch(loadReturnLotsData(date))
    setIsLoading(false)
  }, [ date])

  return (
    <div className={classes.element}>
      <Typography className={classes.title}>Retours de prod</Typography>
      <Typography className={classes.subtitle}>
        Saisissez les informations liées à votre article pour le remettre en stock.
      </Typography>
      <div className={classes.container}>
        <Formik
          initialValues={{ supplierItem: null, lot: null, quantity: "" }}
          onSubmit={async (values, { resetForm }) => {
            await validate(values.quantity, values.lot)
            setDate(moment.utc().startOf("day").valueOf())
            resetForm()
          }}
        >
          {({ values, setFieldValue, resetForm }) => (
            <Form>
              <FormControl className={classes.formControl} variant="standard">
                <DatePicker
                  id="productionDate"
                  name="productionDate"
                  disableCloseOnSelect={false}
                  showToolbar={false}
                  value={date}
                  inputFormat="DD/MM/YYYY"
                  onChange={(value) => _onDateChange(resetForm, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Date de production"
                      fullWidth
                    />
                  )}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <Autocomplete
                  id="supplierItem"
                  name="supplierItem"
                  options={formatSupplierItemsOptions(supplierItems)}
                  getOptionLabel={(option) => option.label || ""}
                  onChange={(_, value) => {
                    setFieldValue("supplierItem", value)
                    setFieldValue("lot", null)
                    setFieldValue("quantity", "")
                  }}
                  value={values.supplierItem}
                  isOptionEqualToValue={(option, value) =>
                    option?.label === value?.label
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Article fournisseur"
                      fullWidth
                    />
                  )}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <Autocomplete
                  id="lot"
                  name="lot"
                  options={
                    values.supplierItem
                      ? formatLotNumberOptions(sortedLots, values.supplierItem)
                      : []
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={(_, value) => {
                    setFieldValue("lot", value)
                    setFieldValue("quantity", "")
                    value && setUnitWeight(generateOrderUnitLabel(value.data.orderSupplierItem, "stock"))
                  }}
                  value={values.lot}
                  isOptionEqualToValue={(option, value) =>
                    option?.label === value?.label
                  }
                  disabled={values.supplierItem === null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Lot (DLC)"
                      fullWidth
                    />
                  )}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  variant="standard"
                  label="Quantité"
                  id="quantity"
                  name="quantity"
                  type="number"
                  placeholder="Quantité"
                  value={values.quantity}
                  disabled={values.lot === null}
                  onChange={(e) =>
                    formatQuantity(e, setFieldValue)
                  }
                  onFocus={() => setFieldValue("isQuantityFocused", true)}
                  onBlur={() => setFieldValue("isQuantityFocused", false)}
                  fullWidth
                />
                {(values.isQuantityFocused || values.quantity) && (
                  <Typography className={classes.quantityUnit}>
                    {unitWeight}
                    {values.quantity > 1 && values.lot.data.orderSupplierItem.units.stock.unity.unity >= 2 ? "s" : ""}
                  </Typography>
                )}
              </FormControl>

              <Button
                className={classes.submitButton}
                color="primary"
                type="submit"
                disabled={
                  loading ||
                  values.supplierItem === null ||
                  values.lot === null ||
                  values.quantity === ""
                }
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "REMETTRE EN STOCK"
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default LotReturnModal
